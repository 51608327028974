import Icons from './icons/Icons';
import './App.css';
import { Icon } from '@iconify/react';
function App() {
	return (
		<div className='App'>
			<img
				src='https://media.licdn.com/dms/image/C5603AQGfZ0-6mekt4g/profile-displayphoto-shrink_800_800/0/1656025021194?e=1678924800&v=beta&t=2NlG6l5j100b1hKZFlmiBPe91we0Rs3fJDC0njQRWsQ'
				alt='me!'
				width='200'
				height='200'
				style={{ borderRadius: '50%', alignSelf: 'center' }}
			/>
			<h1 className='main'>Huzaifa</h1>
			<h2 className='sub'>
				Math & Computer Science student at Western Washington University
			</h2>
			<h3 style={{ color: 'white' }}>
				Site under construction - check back soon!
			</h3>
			<Icons />
		</div>
	);
}

export default App;
