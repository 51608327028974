import { Icon } from '@iconify/react';
import './icons.css';
const Icons = () => {
	return (
		<div className='container'>
			<ul className='icons-list'>
				<li className='icon-container'>
					<a
						style={{ textDecoration: 'none', textAlign: 'center' }}
						target='_blank'
						rel='noreferrer'
						href='https://twitter.com/huzaifa_dev'
					>
						<Icon className='icon' icon='logos:twitter' />
						<span className='icon-text'>Twitter</span>
					</a>
				</li>
				<li className='icon-container'>
					<a
						style={{ textDecoration: 'none', textAlign: 'center' }}
						target='_blank'
						rel='noreferrer'
						href='https://github.com/huthaifa1'
					>
						<Icon className='icon' icon='logos:github-icon' />
						<span className='icon-text'>Github</span>
					</a>
				</li>
				<li className='icon-container'>
					<a
						style={{ textDecoration: 'none', textAlign: 'center' }}
						target='_blank'
						rel='noreferrer'
						href='https://www.linkedin.com/in/huzaifa-almustafa-9a785b1ba/'
					>
						<Icon className='icon' icon='logos:linkedin-icon' />
						<span className='icon-text'>LinkedIn</span>
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Icons;
